import { useState, useRef, useEffect } from 'react';

import { ChevronDown } from '@gds/Icons/Paths/ChevronDown';
import { useAtom } from 'jotai';

import { makeAtom } from 'Atoms/Search/MakeAtom/MakeAtom';
import { MakeModelAtom } from 'Atoms/Search/MakeAtom/MakeAtom.entity';
import { modelAtom } from 'Atoms/Search/ModelAtom/ModelAtom';
import { getFilteredModels } from 'Utils/Search/GetFilteredModels';

import { ModelProps } from '../../Search.entity';
import styles from '../../Search.module.css';

import CustomDropDown from '../CustomDropDown/CustomDropDown';

const Model = ({
  modelPlaceholder,
  searchPlaceholder,
  noResultText,
  modelTitle,
  state,
  updateFn,
  defaultCount,
  isReviewsPage,
  reviewItems,
}: ModelProps) => {
  const DEFAULT_OPTION = {
    key: 'any_model',
    displayName: modelPlaceholder,
    count: defaultCount,
    make: 'anymodel',
  };
  const modelRef = useRef<HTMLInputElement>(null);
  const [selectedMake] = useAtom(makeAtom);

  let eligibleModels: MakeModelAtom[];

  if (isReviewsPage && reviewItems) {
    eligibleModels = reviewItems
      .filter(({ key, models }) => key === selectedMake.key && models)
      .flatMap(({ models }) =>
        models.map(({ codename, name }) => ({
          key: codename,
          displayName: name,
          count: 0,
          make: selectedMake.key,
        })),
      );
  } else {
    eligibleModels = getFilteredModels(state.models as MakeModelAtom[], selectedMake);
  }

  const [isOpen, setIsOpen] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [filteredModels, setFilteredModels] = useState<MakeModelAtom[]>([]);

  const [selectedModel, setSelectedModel] = useAtom(modelAtom);

  const handleMenuOpen = () => {
    setIsOpen(isOpen => !isOpen);
    setSearchKeyword('');
  };

  useEffect(() => {
    const filteredArr =
      eligibleModels &&
      eligibleModels.filter(data => {
        return data.displayName.toLowerCase().includes(searchKeyword);
      });
    setFilteredModels(searchKeyword ? filteredArr : []);
  }, [searchKeyword]);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (!modelRef?.current?.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
  }, [modelRef]);

  const handleSearchChange = (value: string) => {
    setSearchKeyword(value.toLowerCase());
  };

  const handleMenuClose = (option: MakeModelAtom) => {
    setSelectedModel(option);
    updateFn({ ...state, totalCount: option.count });
    setIsOpen(false);
  };

  // check is the model is disabled
  const isMakeDisabled = !eligibleModels.some(data => data.make === selectedMake?.make);

  return (
    <div
      className={`${styles.makeModelWrapper} ${isOpen ? styles.active : ''} ${
        isMakeDisabled ? styles.disabled : ''
      }`}
      ref={modelRef}
      data-test-id="model-component"
      aria-label="model-value"
    >
      <div
        className={styles.dropdownWrapper}
        onClick={handleMenuOpen}
        data-test-id="model-menu-button"
      >
        <span className={styles.makeValue} data-test-id="model-value">
          {selectedModel?.key === 'any_model' ? modelPlaceholder : selectedModel?.displayName}
        </span>
        <div className={styles.iconWrapper}>
          <ChevronDown className={isOpen ? styles.rotate : ''} />
        </div>
      </div>
      {isOpen && (
        <CustomDropDown
          items={searchKeyword.length > 0 ? filteredModels : [DEFAULT_OPTION, ...eligibleModels]}
          isGrouped={false}
          onSelect={handleMenuClose}
          checkedValue={selectedModel?.key}
          dataTestId="model-menu"
          searchPlaceholder={searchPlaceholder}
          handleSearchChange={handleSearchChange}
          noResultText={noResultText}
          dropdownType={'model'}
          handleMenuOpen={handleMenuOpen}
          title={modelTitle}
          countLabel={!isReviewsPage}
        />
      )}
    </div>
  );
};
export default Model;
