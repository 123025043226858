import { atom } from 'jotai';

import { MakeModelAtom } from './MakeAtom.entity';

export const makeAtom = atom<MakeModelAtom>({
  key: 'any_make',
  displayName: 'Any Make',
  count: 0,
  make: 'anymake',
});
