import Button from '@gds/Button/Button';

import { searchWidgetMessages } from '../../../SearchWidget/SearchWidget.messages';

import styles from './BuyOnlineCta.module.css';

interface CtaProps {
  strings: Record<keyof typeof searchWidgetMessages, string>;
  buyOnlineCtaUrl: string;
}

const BuyOnlineCta = ({ strings, buyOnlineCtaUrl }: CtaProps) => {
  return (
    <div className={styles.buyOnlineCta}>
      <div className={styles.or}>
        <span className={styles.copy}>{strings.or}</span>
      </div>
      <Button
        Component={'a'}
        aria-label="buyonline-button"
        className={styles.button}
        fullWidth
        href={buyOnlineCtaUrl}
        type="submit"
        variant="outlined"
      >
        {strings.buyOnlineCta}
      </Button>
    </div>
  );
};

export default BuyOnlineCta;
