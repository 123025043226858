'use client';

import React, { useEffect } from 'react';

import { Grid } from '@gds/Grid/Grid';
import { useSetAtom, useAtomValue } from 'jotai';
import dynamic from 'next/dynamic';
import { usePathname, useRouter } from 'next/navigation';

import { filtersListAtom } from 'Atoms/App/Plp/FiltersListAtom';
import { makeAtom } from 'Atoms/Search/MakeAtom/MakeAtom';
import { modelAtom } from 'Atoms/Search/ModelAtom/ModelAtom';
import { regionAtom } from 'Atoms/Search/RegionAtom/RegionAtom';
import { useSearchCtaUrl } from 'Hooks/UseSearchCtaUrl';

import AutobizCta from '../Autobiz/AutobizCta/AutobizCta';

import BuyOnlineCta from './Components/BuyOnlineCta/BuyOnlineCta';
import { ClearRegionAreaCta } from './Components/ClearRegionAreaCta/ClearRegionAreaCta';
import { MakeModelWidget } from './Components/MakeModelWidget/MakeModelWidget';
import Postcode from './Components/Postcode/Postcode';
import SearchCTA from './Components/SearchCTA/SearchCTA';
import { SearchWidgetProps } from './Search.entity';
const RegionAreaWidget = dynamic(() =>
  import('./Components/RegionAreaWidget/RegionAreaWidget').then(mod => mod.RegionAreaWidget),
);

import styles from './Search.module.css';

const SearchWidget = ({
  hasAutobiz,
  isReviewsMakePage,
  isDealershipPage,
  isReviewsPage,
  isCompactSearch,
  locale,
  locationAttrs,
  notInTab,
  plpUrl,
  postcodeRequired,
  reviewItems,
  strings,
  locationCombinedParams,
  hasBuyOnline,
}: SearchWidgetProps) => {
  const router = useRouter();
  const pathname = usePathname();
  const isLocationEnabled: boolean = !!locationAttrs?.areas && !!locationAttrs?.regions;

  const { searchUrl, isFormValid } = useSearchCtaUrl(
    postcodeRequired,
    locale,
    plpUrl,
    isLocationEnabled,
  );
  const setActiveFiltersAtom = useSetAtom(filtersListAtom);
  const selectedMake = useAtomValue(makeAtom);
  const selectedRegion = useAtomValue(regionAtom);
  const selectedModel = useAtomValue(modelAtom);

  //When aggregations are fetched they take the active Filters into account
  //So we need to remove them for accurate numbers
  useEffect(() => {
    setActiveFiltersAtom([]);
  }, []);

  const getDesktopItemGridSpan = () => {
    if (isCompactSearch) return 12;
    if (hasAutobiz) return 2;
    if (hasBuyOnline) return 2;
    if (!postcodeRequired) return 4;
    return 3;
  };

  const getTabletItemGridSpan = () => {
    if (isCompactSearch) return 12;
    if (!postcodeRequired) return 4;
    return 3;
  };

  const selectedMakeId = selectedMake.make;
  const desktopItemGridSpan = getDesktopItemGridSpan();
  const tabletItemGridSpan = getTabletItemGridSpan();
  const buttonItemSpan = isCompactSearch ? 12 : 3;

  const reviewSearchUrl =
    `/${locale}` +
    '/reviews/' +
    selectedMakeId +
    (selectedModel?.key !== 'any_model' ? '/' + selectedModel?.key : '');

  const buyOnlineCtaUrl = searchUrl.includes('ecom=true') ? searchUrl : `${searchUrl}&ecom=true`;

  const isSelectedMakeReviewsPage =
    selectedMakeId === 'anymake' ||
    (pathname?.endsWith(selectedMakeId) && selectedModel?.key === 'any_model');

  const isSearchDisabled =
    (isReviewsMakePage && isSelectedMakeReviewsPage) ||
    (selectedMakeId === 'anymake' && isReviewsPage) ||
    (selectedRegion.region === 'anyregion' && isLocationEnabled);

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (isLocationEnabled) return router.push(searchUrl);
    if (!isFormValid && !isReviewsPage) return;
    router.push(isReviewsPage ? reviewSearchUrl : searchUrl);
  };

  return (
    <Grid.Container
      component="form"
      id="search-widget-form"
      className={`${styles.searchWrapper} ${notInTab && !hasBuyOnline ? styles.noParent : ''} ${
        hasAutobiz || hasBuyOnline ? styles.noParentWide : ''
      } ${isReviewsMakePage ? styles.wrapperWithText : ''} search-widget`}
      onSubmit={onSubmit}
    >
      <Grid.Row
        align="middle"
        gutter={{ mobile: 0, tablet: 0, desktop: 0 }}
        className={`${styles.searchWrapperRow} ${isCompactSearch ? styles.compactSearch : ''}`}
      >
        {isLocationEnabled && locationAttrs ? (
          <RegionAreaWidget
            locationAttrs={locationAttrs}
            strings={strings}
            tabletItemGridSpan={tabletItemGridSpan}
            desktopItemGridSpan={desktopItemGridSpan}
            locationCombinedParams={locationCombinedParams}
          />
        ) : (
          <MakeModelWidget
            locale={locale}
            strings={strings}
            tabletItemGridSpan={tabletItemGridSpan}
            desktopItemGridSpan={desktopItemGridSpan}
            isReviewsPage={isReviewsPage}
            reviewItems={reviewItems}
          />
        )}
        {!isLocationEnabled && !isReviewsPage && (
          <Grid.Col
            className={`${styles.searchCol} ${styles.postcodeWrapper}`}
            width={{ mobile: 12, tablet: 3, desktop: desktopItemGridSpan }}
          >
            <Postcode
              className="searchWidgetPostcode"
              isRequired={postcodeRequired}
              placeholder={strings.postcodePlaceholder}
              validationError={strings.postcodeError}
              locale={locale}
            />
          </Grid.Col>
        )}
        {isDealershipPage ? (
          <Grid.Col
            className={styles.searchCol}
            width={{ mobile: 12, tablet: buttonItemSpan, desktop: buttonItemSpan }}
          >
            <ClearRegionAreaCta
              buttonText={strings.clearCta}
              disabled={selectedRegion.region === 'anyregion'}
              areaPlaceholder={strings.areaPlaceholder}
              regionPlaceholder={strings.regionPlaceholder}
            />
          </Grid.Col>
        ) : (
          <Grid.Col
            className={styles.searchCol}
            width={{ mobile: 12, tablet: buttonItemSpan, desktop: buttonItemSpan }}
          >
            <SearchCTA
              searchButton={isReviewsPage ? strings.viewReviews : strings.searchButton}
              loadingText={strings.loadingText}
              plpUrl={plpUrl}
              locale={locale}
              disabled={isSearchDisabled}
              showLoadingCta={isReviewsPage || isLocationEnabled}
              isLocationPage={isLocationEnabled}
            />
          </Grid.Col>
        )}

        {hasAutobiz && (
          <Grid.Col className={styles.searchCol} width={{ mobile: 12, tablet: 12, desktop: 3 }}>
            <AutobizCta strings={strings} />
          </Grid.Col>
        )}
        {hasBuyOnline && (
          <Grid.Col className={styles.searchCol} width={{ mobile: 12, tablet: 12, desktop: 3 }}>
            <BuyOnlineCta strings={strings} buyOnlineCtaUrl={buyOnlineCtaUrl} />
          </Grid.Col>
        )}
      </Grid.Row>
    </Grid.Container>
  );
};

export default SearchWidget;
