import { FR_LOCALE } from '@core/Constants/Locale.constants';

import { DEFAULT_SORTING } from '@core/Constants/Sort';
import { Locale } from '@core/Entities/Locale/Locale.entity';
import { getModelNameFromId } from '@core/Services/Search/Utils/GetModelNameFromId';
import { useAtomValue, useAtom } from 'jotai';

import { aggregationsAtom } from 'Atoms/Search/AggregationsAtom/AggregationsAtom';
import { areaAtom } from 'Atoms/Search/AreaAtom/AreaAtom';
import { makeAtom } from 'Atoms/Search/MakeAtom/MakeAtom';
import { modelAtom } from 'Atoms/Search/ModelAtom/ModelAtom';
import { locationAtom } from 'Atoms/Search/PostcodeAtom/LocationAtom';
import { postcodeAtom } from 'Atoms/Search/PostcodeAtom/PostcodeAtom';
import { regionAtom } from 'Atoms/Search/RegionAtom/RegionAtom';
import { DEFAULT_DISTANCE } from 'Components/Plp/Filters/Components/LocationFilter/LocationFilter.constants';

const useSearchCtaUrl = (
  postcodeRequired: boolean,
  locale: Locale,
  plpUrl?: string,
  isLocationHub?: boolean,
) => {
  const [selectedMake] = useAtom(makeAtom);
  const [selectedModel] = useAtom(modelAtom);
  const { isValid, location, error } = useAtomValue(locationAtom);
  const postcode = useAtomValue(postcodeAtom);
  const { totalCount } = useAtomValue(aggregationsAtom);
  const isAnyMakeSelected = selectedMake && selectedMake.key !== 'any_make';
  const isAnyModelSelected = selectedModel && selectedModel.key !== 'any_model';

  const selectedRegion = useAtom(regionAtom);
  const selectedArea = useAtom(areaAtom);

  const slugParams = () => {
    // When only make is selected, the query params will be:/volkswagen
    // When make and model are selected, the query params will be:/volkswagen/beetle

    const modelIdForSlug = getModelNameFromId(selectedModel.key);
    const makeKey = (isAnyMakeSelected && `/make/${selectedMake.key}`) || '';
    const modelKey = (isAnyModelSelected && `/model/${modelIdForSlug}`) || '';
    return makeKey + modelKey;
  };

  const getLocationParams = (distance?: number) => {
    // only check the lat and lon when API returns location
    if (isValid) {
      const { lat, lon } = location;
      const correctPC = postcode[locale];
      if (lat && lon && correctPC) {
        let queryString = `?lat=${lat}&lon=${lon}&postcode=${correctPC}`;
        if (distance) {
          queryString += `&max-distance=${distance}`;
        }

        return queryString;
      }
    }
    return '';
  };

  const getLocationHubPath = () => {
    if (selectedRegion[0].key !== 'any_region') {
      if (selectedArea[0].key !== 'any_area') {
        if (locale === 'uk') {
          return `/${selectedRegion[0].key}/${selectedArea[0].key}`;
        } else {
          return `/${selectedRegion[0].regionCode}/${selectedArea[0].key}`;
        }
      } else {
        if (locale === 'uk') {
          return `/${selectedRegion[0].key}`;
        } else {
          return `/${selectedRegion[0].regionCode}`;
        }
      }
    } else {
      return ``;
    }
  };

  const getSortingPath = () => {
    let sortingParam = `&sort=${DEFAULT_SORTING[locale]}`;

    if (getLocationParams() === '') {
      sortingParam = `?sort=${DEFAULT_SORTING[locale]}`;
    }

    return sortingParam;
  };

  const getPlpURL = () => {
    let plpUrlSlug = '';

    if (plpUrl) {
      const { lat, lon } = location;
      lat && lon && postcode[locale] ? (plpUrlSlug = `&${plpUrl}`) : (plpUrlSlug = `?${plpUrl}`);
    }

    // as FR cta doesn't include sorting params
    if (getLocationParams() === '' && plpUrlSlug && locale !== FR_LOCALE) {
      plpUrlSlug = `&${plpUrl}`;
    }

    return plpUrlSlug;
  };

  // final search url
  const getSearchListingURL = () => {
    const frCtaUrl = isLocationHub
      ? `/fr/locations${getLocationHubPath()}`
      : `/fr/autos${slugParams()}${getLocationParams(DEFAULT_DISTANCE['fr'])}${getPlpURL()}`;

    const ukCtaUrl = isLocationHub
      ? `/uk/locations${getLocationHubPath()}`
      : `/uk/autos${slugParams()}${getLocationParams()}${getSortingPath()}${getPlpURL()}`;

    switch (locale) {
      case FR_LOCALE:
        return frCtaUrl;
      default:
        return ukCtaUrl;
    }
  };

  // check if the form is valid
  const getIsFormValid = () => {
    const isPostCodeValid = postcodeRequired ? !error && isValid : true;
    return isLocationHub ? true : totalCount > 0 && isPostCodeValid;
  };

  return { searchUrl: getSearchListingURL(), isFormValid: getIsFormValid() };
};

export { useSearchCtaUrl };
